const chartSettings = {
  dataName: {
    speed: 'r/min'
  },
  seriesMap: {
    speed: {
      min: 0,
      max: 1200,
      splitNumber: 6,
      radius: '100%',
      axisLine: {
        lineStyle: {
          color: [[0.09, 'lime'], [0.82, '#1e90ff'], [1, '#ff4500']],
          width: 3,
          shadowColor: '#999',
          shadowBlur: 5
        }
      },
      axisLabel: {
        textStyle: {
          color: '#C0C4CC',
          shadowColor: '#fff',
          fontSize: 11,
          shadowBlur: 10
        }
      },
      axisTick: {
        length: 10,
        lineStyle: {
          color: 'auto',
          shadowColor: '#fff',
          shadowBlur: 10
        }
      },
      splitLine: {
        length: 15,
        lineStyle: {
          width: 3,
          color: '#fff',
          shadowColor: '#fff',
          shadowBlur: 10
        }
      },
      pointer: {
        width: 3,
        shadowColor: '#fff',
        shadowBlur: 5
      },
      title: {
        offsetCenter: [0, '-30%'],
        textStyle: {
          fontWeight: 'bolder',
          fontStyle: 'italic',
          color: '#C0C4CC',
          shadowColor: '#fff',
          shadowBlur: 10
        }
      },
      detail: {
        borderColor: '#fff',
        shadowColor: '#fff',
        shadowBlur: 5,
        width: 80,
        height: 30,
        offsetCenter: [10, '15%'],
        textStyle: {
          fontWeight: 'bolder',
          fontSize: 17,
          fontStyle: 'oblique',
          color: '#fff'
        }
      }
    }
  }
}

const chartSmall = (dataName, min, max, length) => {
  return {
    dataName: {
      speed: dataName
    },
    seriesMap: {
      speed: {
        min: min,
        max: max,
        splitNumber: length,
        radius: '100%',
        axisLine: {
          lineStyle: {
            color: [[0.09, 'lime'], [0.82, '#1e90ff'], [1, '#ff4500']],
            width: 1,
            shadowColor: '#999',
            shadowBlur: 0
          }
        },
        axisLabel: {
          textStyle: {
            color: '#909399',
            fontSize: 11,
            shadowColor: '#fff',
            shadowBlur: 5
          }
        },
        axisTick: {
          length: 5,
          lineStyle: {
            color: 'auto',
            shadowColor: '#fff',
            shadowBlur: 5
          }
        },
        splitLine: {
          length: 8,
          lineStyle: {
            width: 2,
            color: '#fff',
            shadowColor: '#fff',
            shadowBlur: 5
          }
        },
        pointer: {
          width: 4,
          shadowColor: '#fff',
          shadowBlur: 5
        },
        title: {
          offsetCenter: [0, '-30%'],
          textStyle: {
            fontWeight: 'bolder',
            fontStyle: 'italic',
            fontSize: 13,
            color: '#909399',
            shadowColor: '#fff',
            shadowBlur: 10
          }
        },
        detail: {
          borderColor: '#fff',
          shadowColor: '#fff',
          shadowBlur: 5,
          width: 80,
          height: 30,
          offsetCenter: [0, '15%'],
          textStyle: {
            fontWeight: 'bolder',
            fontSize: 15,
            fontStyle: 'oblique',
            color: '#fff'
          }
        }
      }
    }
  }
}

const iotConfig = (vm, data) => {
  const keys = [
    'time', 'MassFlowRate', 'Density',
    'Temperature', 'BatchMassTotal', 'MassInventory', 'VolumeFlowRate',
    'LiveZero', 'DriveGain', 'RawTubeFrequency', 'RightPickoffVoltage',
    'LeftPickoffVoltage', 'FlowSignalOffset', 'BatchVolumeTotal', 'APICorrectionVolumeTotal',
    'TempAPICorrectionDensity', 'APIAverageCorrectionDensity', 'APIAverageCorrectionTemperature', 'CurrentCorrection',
    'LiquidDetectionStatus',
    'Aeration', 'Pressure', 'FieldVerificationZero', 'DeltaT'
  ]
  data = data || []
  data = data.map(item => {
    return keys.map(key => item[key])
  })
  return {
    header: [
      vm.$t('time'),
      vm.$t('massFlowRate'), // MassFlowRate
      vm.$t('density'), // Density
      vm.$t('temperature'), // Temperature
      vm.$t('massTotal'), // BatchMassTotal
      vm.$t('massInventory'), // MassInventory
      vm.$t('volumeFlowRate'), // VolumeFlowRate
      vm.$t('liveZero'), // LiveZero
      vm.$t('driveGain'), // DriveGain
      vm.$t('rawTubeFrequency'), // RawTubeFrequency
      vm.$t('rightPickoffVoltage'), // RightPickoffVoltage
      vm.$t('leftPickoffVoltage'), // LeftPickoffVoltage
      vm.$t('flowSignalOffsetAtZeroFlow'), // FlowSignalOffset
      vm.$t('volumeTotal'), // BatchVolumeTotal
      vm.$t('APICorrectedVolumeTotal'), // APICorrectionVolumeTotal
      vm.$t('tempAPICorrectedDensity'), // TempAPICorrectionDensity
      vm.$t('APIAvgCorrectedDensity'), // APIAverageCorrectionDensity
      vm.$t('APIAvgCorrectedTemperature'), // APIAverageCorrectionTemperature
      vm.$t('currentCorrection'), // CurrentCorrection
      vm.$t('discreteInput1Status'), // LiquidDetectionStatus
      vm.$t('maxAerationLimit'), // Aeration
      vm.$t('externalPressureInput'), // Pressure
      vm.$t('fieldVerificationZero'), // FieldVerificationZero
      vm.$t('deltaT') // DeltaT
      // vm.$t('statusReports')
    ],
    data: data,
    rowNum: 10,
    columnWidth: [200, 140, 120, 130, 120, 130, 160, 170, 120, 180, 180, 180, 260, 140, 220, 220, 210, 240, 160, 180, 160, 190, 180, 160],
    headerBGC: '#060e1b',
    waitTime: 3000
  }
}

module.exports = {
  chartSettings,
  iotConfig,
  chartSmall
}
